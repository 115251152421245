.container {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 90vh;
  overflow-y: auto;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.date {
  text-align: center;
  font-style: italic;
  margin-bottom: 20px;
}

.content {
  line-height: 1.6;
  text-align: justify;
}

ul {
  padding-left: 20px;
}

ul li {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.88);
  word-break: break-word;
  line-height: 1.5714285714285714;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .container {
    padding: 15px;
  }
}
